import React from 'react';

const CTA = ({ size, color, bold, title, subtitle, cta, box }) => (
	<section className={`hero is-${size} is-${color} ${!bold || 'is-bold'} ${!box || 'box'}`}>
		<div className="hero-body">
			<div className="container">
				<div className="content has-text-centered">
					<h2 className="title is-size-3 is-spaced">{title}</h2>
					<h3 className="subtitle is-size-4">{subtitle}</h3>
					<br />
					{cta && (
						<a href={cta.url} className="button is-light is-large">
							<strong>{cta.label}</strong>
						</a>
					)}
				</div>
			</div>
		</div>
	</section>
);

export default CTA;
