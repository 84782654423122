import React from 'react';
import { Link } from 'gatsby';
import {
	Columns,
	Column,
	Container,
	Footer,
	Level,
	Left,
	Item,
	Right,
	Menu,
	MenuItem,
	Section
} from '../components/bulma';

import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import twitter from '../img/social/twitter.svg';
import linkedin from '../img/social/linkedin.svg';

import appStore from '../img/AppStore.svg';
import googlePlay from '../img/GooglePlay.svg';
import finhub from '../img/finhub-logo.svg';

export default () => {
	return (
		<Footer>
			<Container>
				<Columns>
					<Column>
						<Menu label="Cent">
							<MenuItem to="/why">Why Cent</MenuItem>
							<MenuItem to="/how-it-works">How it works</MenuItem>
							<MenuItem to="/fees">Fees</MenuItem>
							<MenuItem to="/get-started">Getting Started</MenuItem>
							<MenuItem to="/business">For Business</MenuItem>
						</Menu>
					</Column>
					<Column>
						<Menu label="What you can do">
							<MenuItem to="/features/send-money">Send money</MenuItem>
							<MenuItem to="/features/pay-bills">Pay bills</MenuItem>
							<MenuItem to="/features/shop-worldwide">Shop worldwide</MenuItem>
							<MenuItem to="/features/mobile-wallet">Mobile Wallet</MenuItem>
							<MenuItem to="/features/debit-card">Debit card</MenuItem>
						</Menu>
					</Column>

					<Column>
						<Menu label="Resources">
							<MenuItem to="/blog">Blog</MenuItem>
							<MenuItem to="/white-label">White-label</MenuItem>
							<MenuItem to="/faq">FAQ</MenuItem>
							<MenuItem to="/digest">All about money</MenuItem>
							<MenuItem to="/apps">Download Apps</MenuItem>
							<MenuItem to="/developers">Developers</MenuItem>
						</Menu>
					</Column>

					<Column>
						<Menu label="Company">
							<MenuItem to="/about">About</MenuItem>
							<MenuItem to="/mission">Mission</MenuItem>
							<MenuItem to="/partners">Partners</MenuItem>
							<MenuItem to="/news">News</MenuItem>
							<MenuItem to="/contact">Contact</MenuItem>
						</Menu>
					</Column>
				</Columns>
				<br />
				<Section>
					<Level>
						<Left>
							<p>
								<a title="facebook" href="https://www.facebook.com/paymentninja/">
									<img
										src={facebook}
										alt="Facebook"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="twitter" href="https://twitter.com/PaymentNinja">
									<img
										src={twitter}
										alt="Twitter"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="linkedin" href="https://www.linkedin.com/company/cent-cash/">
									<img
										src={linkedin}
										alt="Twitter"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="instagram" href="https://www.instagram.com/payment.ninja/">
									<img src={instagram} alt="Instagram" style={{ width: '2em', height: '2em' }} />
								</a>
							</p>
						</Left>
						<Item>
							<Link className="navbar-item" to="/terms">
								Terms of service
							</Link>
							<Link className="navbar-item" to="/privacy">
								Privacy
							</Link>
						</Item>
						<Right>
							<p>
								<a title="App Store" href="#">
									<img src={appStore} alt="App Store" />
								</a>{' '}
								<a title="Google Play" href="#">
									<img className="fas fa-lg" src={googlePlay} alt="Google Play" />
								</a>
							</p>
						</Right>
					</Level>
					<hr />

					<Level>
						<Left>
							<p className="has-text-left is-size-7">
								<strong>© 2020 Cent</strong>. A{' '}
								<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
									FinHub Global Ventures
								</a>{' '}
								company. Software development by{' '}
								<a title="Fastlabs" href="https://fastlabs.co/" target="_blanc">
									Fastlabs
								</a>.
								<br />All rights reserved. All product names, trademarks and registered trademarks are
								property of their respective owners.
								<br />All company, product and service names used in this website are for identification
								purposes only.
								<br />Use of these names, trademarks and brands does not imply endorsement.
							</p>
						</Left>
						<Right>
							<div
								style={{
									width: '120px',
									display: 'inline-block'
								}}
							>
								<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
									<img src={finhub} alt="FinHub Global Ventures logo" />
								</a>
							</div>
						</Right>
					</Level>
				</Section>
			</Container>
			<br />
			<br />
		</Footer>
	);
};
